import { useState, useEffect, useCallback } from 'react';

// When the VOTEapp is opened in the browser and the user switches to another browser tab,
// pause/resume the periodic GET request
function getVisibilityPropertyNames() {
  // Opera 12.10 and Firefox 18 and later support
  if (typeof document.hidden !== 'undefined') {
    return ['hidden', 'visibilitychange'];
  }

  if (typeof document.msHidden !== 'undefined') {
    return ['msHidden', 'msvisibilitychange'];
  }

  if (typeof document.webkitHidden !== 'undefined') {
    return ['webkitHidden', 'webkitvisibilitychange'];
  }

  return ['hidden', 'visibilitychange'];
}

const [hidden, visibilityChange] = getVisibilityPropertyNames();

function isDocumentHidden() {
  return document[hidden];
}

function useDocumentVisibilityChange(callback) {
  const onChange = useCallback(() => {
    callback(isDocumentHidden());
  }, [callback]);

  useEffect(() => {
    window.addEventListener(visibilityChange, onChange);

    return () => window.removeEventListener(visibilityChange, onChange);
  }, [onChange]);
}

export function useDocumentVisibility() {
  const [hidden, setHidden] = useState(isDocumentHidden());

  const onChange = useCallback(state => setHidden(state), [setHidden]);

  useDocumentVisibilityChange(onChange);

  return hidden;
}
