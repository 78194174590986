import React from "react";
import { TwitterShareButton } from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

const SocialMedia = ({ mode, twitterhashtags, twittertemplate }) => {
  if (mode.kiosk) {
    return null;
  }

  return (
    <div className="rxmg-social-media">
      <div className="sb-default">
        <TwitterShareButton
          className="sb-wrapper"
          title={twittertemplate}
          hashtags={
            twitterhashtags && twitterhashtags.length > 0
              ? twitterhashtags.split(",")
              : ""
          }
          url="rxmusic.com"
        >
          <FontAwesomeIcon icon={faTwitter} size="lg" />
        </TwitterShareButton>
      </div>
    </div>
  );
};

export default SocialMedia;
