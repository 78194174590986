import React, { memo } from "react";
import { useAnimateTypewriter } from "../../../../hooks/useAnimateTypewriter";

const TrackArtistInfo = ({ artist }) => {
  const animatedText = useAnimateTypewriter(artist);

  return (
    <div className="rxmg-artist-component">
      <div className="rxmg-artist-label">ARTIST</div>
      <div className="rxmg-artist-name">{animatedText}</div>
    </div>
  );
};

export default memo(TrackArtistInfo);
