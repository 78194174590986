import React, { useEffect } from "react";
import { useFetchTrackData } from "../../../hooks/useFetchTrackData";
import * as gtag from "../../../lib/gtag";
import Header from "../Header";
import TrackInfo from "../TrackInfo";
import VoteControls from "../VoteControls";
import SocialMedia from "../SocialMedia";
import Footer from "../Footer";
import ErrorNotification from "../ErrorNotification";
import Spinner from "../../../common/Spinner";

const VoteApp = (props) => {
  const { state } = useFetchTrackData(props.location.search);
  const { isLoading, error, track, appConfig } = state;

  const isOffline = () => !window.navigator.onLine;

  useEffect(() => {
    gtag.init();
  }, []);

  useEffect(() => {
    if (track.customcssuri) {
      let $customCSSLink = document.getElementById("customCSS");

      if (!$customCSSLink) {
        $customCSSLink = document.createElement("link");
        $customCSSLink.rel = "stylesheet";
        $customCSSLink.href = track.customcssuri;
        document.head.appendChild($customCSSLink);
      }
    }
  }, [track.customcssuri]);

  if ((isLoading && !track.artist) || (!track.artist && !error)) {
    return (
      <div className="rxmg-thumbs-cover__wrapper">
        <Spinner />
      </div>
    );
  }

  if (error || isOffline()) {
    return <ErrorNotification {...error} />;
  }

  return (
    <div className="rxmg-card">
      <Header />
      <TrackInfo />
      <VoteControls />
      <SocialMedia
        mode={appConfig.mode}
        twittertemplate={track.twittertemplate}
        twitterhashtags={track.twitterhashtags}
      />
      <Footer />
    </div>
  );
};

export default VoteApp;
