import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useFetchTrackData } from '../../../../hooks/useFetchTrackData';

import FrameHeader from '../../../../common/FrameHeader';
import FrameBody from '../../../../common/FrameBody';
import Button from '../../../../common/Button';

const ErrorNowPlayingNotFound = ({ location }) => {
  const { state, fetchData } = useFetchTrackData(location.search);
  const {
    appConfig: { errorRetry_sec }
  } = state;
  const [timer, setTimer] = useState(errorRetry_sec);
  const shouldRetry = timer === 0;

  const convertSecondsToMinuteAndSeconds = duration => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration - minutes * 60;

    const str_pad_left = (string, pad, length) => {
      return (new Array(length + 1).join(pad) + string).slice(-length);
    };

    return str_pad_left(minutes, '0', 1) + ':' + str_pad_left(seconds, '0', 2);
  };

  useEffect(() => {
    let id;
    if (timer <= 0) {
      id = setTimeout(() => {
        fetchData();
      }, 1000);
    } else {
      id = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    }

    return () => {
      clearTimeout(id);
    };
  }, [timer, fetchData]);

  return (
    <div>
      <FrameHeader>uh oh!</FrameHeader>
      <FrameBody>
        <div>Sorry, but the Now Playing Track couldn't be retrieved.</div>
        <br />
        <span>
          {shouldRetry
            ? 'Retrying Now'
            : `Retrying in ${convertSecondsToMinuteAndSeconds(timer)}`}
        </span>
      </FrameBody>
      <Button styles={{ width: '90px' }} onClick={fetchData}>
        Retry Now
      </Button>
    </div>
  );
};

export default withRouter(ErrorNowPlayingNotFound);
