import { useState, useEffect } from 'react';

export const useConnectionChange = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [offline, setOffline] = useState(false);

  function onOffline() {
    setOffline(true);
  }

  function onOnline() {
    setOffline(false);
  }

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!window.navigator.onLine) {
      setOffline(true);
    }
    window.addEventListener('offline', onOffline);
    window.addEventListener('online', onOnline);

    return () => {
      window.removeEventListener('offline', onOnline);
      window.removeEventListener('online', onOnline);
    };
  }, []);

  return { offline, isMounted };
};
