import React from 'react';
import { useVoteAppState } from '../../../hooks/useVoteAppState';
import Logo from './Logo';
import ZoneName from './ZoneName';

const Header = () => {
  const state = useVoteAppState();
  const { track } = state;

  return (
    <React.Fragment>
      <Logo logo={track.zonelogo} />
      <ZoneName name={track.zonename} />
    </React.Fragment>
  );
};

export default Header;
