export const GA_TRACKING_ID = 'UA-86062914-4';

const dimension = 'dimension';

const customDimension_pageLocation_session = `${dimension}1`;
const customDimension_zoneKey_session = `${dimension}2`;
const customDimension_zoneLogo_session = `${dimension}3`;
const customDimension_zoneName_session = `${dimension}4`;
const customDimension_voteMode_session = `${dimension}5`;

const customDimension_trackId_hit = `${dimension}6`;
const customDimension_trackTitle_hit = `${dimension}7`;
const customDimension_trackArtist_hit = `${dimension}8`;

const metric = 'metric';

const customMetric_thumbsUp_hit = `${metric}1`;
const customMetric_thumbsDown_hit = `${metric}2`;
// const customMetric_viewed_hit = `${metric}3`;

export const init = () => {
  const gtag = window.gtag;

  if (typeof gtag === 'function') {
    gtag('js', new Date());
    gtag('config', GA_TRACKING_ID);
    gtag('set', {
      [customDimension_pageLocation_session]: window.location.href
    });
  }
};

export const setZoneApiKey = key => {
  const gtag = window.gtag;

  if (typeof gtag === 'function') {
    gtag('set', { [customDimension_zoneKey_session]: key });
  }
};

export const setZoneNameAndLogo = (name, logo) => {
  const gtag = window.gtag;

  if (typeof gtag === 'function') {
    gtag('set', {
      [customDimension_zoneName_session]: name,
      [customDimension_zoneLogo_session]: logo
    });
  }
};

export const setVoteMode = mode => {
  const gtag = window.gtag;

  if (typeof gtag === 'function') {
    gtag('set', { [customDimension_voteMode_session]: mode });
  }
};

export const setCurrentTrack = (trackId, title, artist) => {
  const gtag = window.gtag;

  if (typeof gtag === 'function') {
    gtag('set', {
      [customDimension_trackId_hit]: trackId,
      [customDimension_trackTitle_hit]: title,
      [customDimension_trackArtist_hit]: artist
    });
  }
};

export const eventVote = voteVal => {
  const gtag = window.gtag;

  if (typeof gtag === 'function') {
    let voteType;

    if (voteVal === 1) {
      voteType = 'Thumbs_Up';
      gtag('set', { [customMetric_thumbsUp_hit]: 1 });
    } else if (voteVal === -1) {
      voteType = 'Thumbs_Down';
      gtag('set', { [customMetric_thumbsDown_hit]: 1 });
    }

    gtag('event', 'vote', {
      event_category: 'vote',
      event_label: voteType,
      value: 1
    });
  }
};

export const eventException = err => {
  const gtag = window.gtag;

  if (typeof gtag === 'function') {
    gtag('event', 'exception', {
      description: err
    });
  }
};
