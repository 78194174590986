import React, { useReducer, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { StateContext } from './StateContext';
import { DispatchContext } from './DispatchContext';
import voteReducer from '../reducers/voteReducer';
import { rxmAppVersion } from '../../constants/cookies';
import {
  configJsonUrl,
  versionJsonUrl,
  appVerCheckRetry_msec
} from '../../constants/config';
import {
  FETCH_CONFIG_REQUEST,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_FAILURE
} from '../../state/actionTypes';

export const AppProvider = ({ children }) => {
  const [config, setConfig] = useState(null);
  const initialState = useContext(StateContext);
  const [state, dispatch] = useReducer(voteReducer, initialState);
  const [cookies, setCookie, removeCookie] = useCookies([rxmAppVersion]);

  // fetch external app config and app version
  // app config is saved to app state
  // app version is saved to cookie storage
  useEffect(() => {
    const fetchConfig = async () => {
      dispatch({ type: FETCH_CONFIG_REQUEST });
      try {
        const configResponse = await axios.get(configJsonUrl);
        const versionResponse = await axios.get(versionJsonUrl);

        if (configResponse) {
          dispatch({
            type: FETCH_CONFIG_SUCCESS,
            payload: configResponse.data
          });
          setConfig(configResponse.data);
        }

        if (versionResponse) {
          setCookie(rxmAppVersion, JSON.stringify(versionResponse.data));
        }
      } catch (e) {
        console.log(e.response.data);
        dispatch({ type: FETCH_CONFIG_FAILURE });
      }
    };

    fetchConfig();
  }, [dispatch, setCookie]);

  // compare app version from local cookie storage vs server app version
  // if server version is higher, reload browser page
  useEffect(() => {
    const compareAppVersions = (local, server) => {
      const localVer = local.split('.');
      const serverVer = server.split('.');

      for (let i = 0; i < localVer.length; i++) {
        if (parseInt(serverVer[i], 10) > parseInt(localVer[i], 10)) {
          removeCookie(rxmAppVersion);
          window.location.reload(true);
        }
      }
    };

    const id = setInterval(async () => {
      const versionResponse = await axios.get(versionJsonUrl);

      if (versionResponse) {
        const localVer = cookies.rxmAppVersion.version;
        const serverVer = versionResponse.data.version;
        compareAppVersions(localVer, serverVer);
      }
    }, appVerCheckRetry_msec);

    return () => clearInterval(id);
  }, [cookies.rxmAppVersion, removeCookie]);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        {config ? children : null}
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
};
