import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AppProvider } from './state/context/AppProvider';
import VoteApp from './views/components/VoteApp';

const App = () => (
  <Router>
    <AppProvider>
      <Route path="/" component={VoteApp} />
    </AppProvider>
  </Router>
);

export default App;
