import React, { useState, useCallback, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useVoteCountdownTimer } from "../../../hooks/useVoteCountdown";
import { useVoteAppState } from "../../../hooks/useVoteAppState";
import { useVoteAppDispatch } from "../../../hooks/useVoteAppDispatch";
import { keyParam } from "../../../constants/config";
import { rxmAppVote } from "../../../constants/cookies";
import {
  SUBMIT_VOTE_FAILURE,
  SUBMIT_VOTE_REQUEST,
  SUBMIT_VOTE_SUCCESS
} from "../../../state/actionTypes";

import ThumbsUp from "./thumbsUp";
import ThumbsDown from "./thumbsDown";
import ThankYouMessage from "./thankYouMessage";
import Spinner from "../../../common/Spinner";

const VoteControls = ({ location }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [vote, setVote] = useState({ up: false, down: false });
  const [cookies, setCookie, removeCookie] = useCookies([rxmAppVote]);
  const { count, setCount } = useVoteCountdownTimer(vote);
  const state = useVoteAppState();
  const dispatch = useVoteAppDispatch();
  const {
    track: { trackid },
    appConfig: { apiUrl, mode }
  } = state;

  const query = new URLSearchParams(location.search).get(keyParam);
  const showThankYou = vote.up || vote.down;

  const neverVoted = useCallback(
    () => vote.up === false && vote.down === false,
    [vote]
  );

  const cookieVoteMatchesCurrentPlaying = useCallback(
    () =>
      trackid &&
      trackid !== undefined &&
      cookies.rxmAppVote &&
      trackid === cookies.rxmAppVote.idtitle,
    [trackid, cookies.rxmAppVote]
  );

  const getSubmitVoteUrl = useCallback(() => {
    return `${apiUrl}/vote?key=${query}`;
  }, [apiUrl, query]);

  const onVoteSubmit = async (idTitle, vote) => {
    const API_URL = getSubmitVoteUrl();
    const voteData = { idTitle, vote };
    dispatch({ type: SUBMIT_VOTE_REQUEST });
    try {
      const { data } = await axios.post(API_URL, voteData);

      // set cookie and cookie expiry
      const expire = new Date().getTime() + 300000;
      const cookieExpireDate = new Date(expire);
      setCookie(rxmAppVote, JSON.stringify(data.data[0]), {
        expires: cookieExpireDate,
        sameSite: false,
        secure: true
      });

      setVote(
        vote === 1 ? { up: true, down: false } : { up: false, down: true }
      );
      dispatch({ type: SUBMIT_VOTE_SUCCESS });
    } catch (e) {
      setTimeout(() => {
        dispatch({ type: SUBMIT_VOTE_FAILURE, payload: e.response.data });
      }, 1700);
    }
  };

  useEffect(() => {
    // component is mounted
    setIsMounted(true);
  }, []);

  // Kiosk Mode
  useEffect(() => {
    // Resets countdown and vote thumb status
    if (mode.kiosk) {
      if ((count === 0 && vote.up) || (count === 0 && vote.down)) {
        setCount(5);
        setVote({ up: false, down: false });
      }
    }
  }, [count, vote, setCount, mode]);

  // Mobile mode
  useEffect(() => {
    if (mode.mobile) {
      const isMatch = cookieVoteMatchesCurrentPlaying();
      const isNeverVoted = neverVoted();

      // if previously voted and vote idTitle doesn't match now playing idTitle
      // remove cookie and reset vote state
      if (!isMatch && isMounted && !isNeverVoted) {
        removeCookie(rxmAppVote);
        setVote({ up: false, down: false });

        // if page refreshes and previous vote idTitle doesn't match now playing idTitle
        // remove cookie
      } else if (!isMatch && isMounted && isNeverVoted) {
        removeCookie(rxmAppVote);
      }

      // if there is title match, set the vote choice based on vote set in cookie
      // which triggers correct thumb render
      if (isMatch && isMounted && isNeverVoted) {
        setVote({
          up: cookies.rxmAppVote.vote === 1,
          down: cookies.rxmAppVote.vote === -1
        });
      }
    }
  }, [
    mode,
    cookieVoteMatchesCurrentPlaying,
    removeCookie,
    neverVoted,
    isMounted,
    cookies.rxmAppVote
  ]);

  if (!isMounted && neverVoted) {
    return null;
  }

  return (
    <div className={`rxmg-thumbs-jar ${mode.kiosk ? "rxmg-vote-kiosk" : ""}`}>
      <ThumbsUp onSubmit={() => onVoteSubmit(trackid, 1)} vote={vote} />
      <ThankYouMessage
        count={count}
        showThankYou={showThankYou}
        vote={vote}
        mode={mode}
      />
      {state.vote.isLoading && <Spinner />}
      <ThumbsDown onSubmit={() => onVoteSubmit(trackid, -1)} vote={vote} />
    </div>
  );
};

export default withRouter(VoteControls);
