import React, { useState, useEffect, useRef } from 'react';
import mojs from 'mo-js';

const ThumbsUp = ({ onSubmit, vote, error }) => {
  const [animation, setAnimation] = useState({
    burst: null,
    shape: null,
    tween: null
  });
  const parentElRef = useRef();
  const voteElRef = useRef();

  const initAnimation = () => {
    const burst = new mojs.Burst({
      parent: parentElRef.current,
      radius: { 30: 90 },
      count: 6,
      children: {
        fill: '#ffd700',
        opacity: 1.0,
        radius: 15,
        duration: 1700,
        easing: mojs.easing.bezier(0.1, 1, 0.3, 1)
      }
    });

    const shape = new mojs.Shape({
      parent: parentElRef.current,
      type: 'circle',
      radius: { 0: 60 },
      fill: 'transparent',
      stroke: '#ffd700',
      strokeWidth: { 20: 0 },
      opacity: 1.0,
      duration: 700,
      easing: mojs.easing.sin.out
    });

    const tween = new mojs.Tween({
      duration: 1200,
      onUpdate(progress) {
        if (progress > 0.3) {
          const elasticOutProgress = mojs.easing.elastic.out(
            1.43 * progress - 0.43
          );
          voteElRef.current.style.WebkitTransform = voteElRef.current.style.transform =
            'scale3d(' + elasticOutProgress + ',' + elasticOutProgress + ',1)';
        } else {
          voteElRef.current.style.WebkitTransform = voteElRef.current.style.transform =
            'scale3d(0,0,1)';
        }
      }
    });

    setAnimation({ burst, shape, tween });
  };

  const animate = () => {
    animation.burst.replay();
    animation.shape.replay();
    animation.tween.replay();
  };

  const handleSubmit = () => {
    animate();
    onSubmit();
  };

  useEffect(() => {
    initAnimation();
  }, []);

  let showThumbClass =
    (vote.up && !vote.down) || (!vote.up && !vote.down)
      ? 'rxmg-show-thumbs'
      : '';
  let justVotedClass = vote.up ? 'rxmg-voted' : '';

  return (
    <div ref={parentElRef} className={`rxmg-thumbs-div ${showThumbClass}`}>
      <span
        ref={voteElRef}
        className={`rxmg-thumbs rxmg-thumbs-up ${justVotedClass}`}
        onClick={handleSubmit}
      />
    </div>
  );
};

export default ThumbsUp;
