import React from 'react';

export const StateContext = React.createContext({
  track: {},
  isLoading: false,
  error: null,
  sessionid: null,
  vote: {
    isLoading: false
  },
  appConfig: {
    apiUrl: null,
    ajaxRefreshDelay: null,
    errorRetrySeconds: null,
    mode: {
      kiosk: false,
      mobile: false
    }
  }
});
