import React, { memo } from "react";
import { useAnimateTypewriter } from "../../../../hooks/useAnimateTypewriter";

const TrackName = ({ title }) => {
  const animatedText = useAnimateTypewriter(title);

  return (
    <div className="rxmg-track-component">
      <div className="rxmg-track-name">{animatedText}</div>
      <div className="rxmg-track-label">TRACK</div>
    </div>
  );
};

export default memo(TrackName);
