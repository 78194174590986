import React from 'react';

const ThankYouMessage = ({ showThankYou, count, vote, mode }) => {
  const positionClass = vote.up
    ? 'rxmg-thank-you-float-right'
    : 'rxmg-thank-you-float-left';

  if (!showThankYou) {
    return null;
  }

  return (
    <div className="rxmg-thank-you-jar">
      <span className={`rxmg-thank-you-msg ${positionClass}`}>
        Thanks for voting!
        {mode.kiosk && (
          <span className="rxmg-thank-you-msg-countdown"> {count}</span>
        )}
      </span>
    </div>
  );
};

export default ThankYouMessage;
