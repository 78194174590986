import React from 'react';
import { useConnectionChange } from '../../../hooks/useConnectionChange';
import ErrorOffline from './ErrorOffline';
import ErrorGeneric from './ErrorGeneric';
import ErrorInvalidUrl from './ErrorInvalidUrl';
import ErrorNowPlayingNotFound from './ErrorNowPlayingNotFound';
import ErrorVoteDisabled from './ErrorVoteDisabled';
import ErrorZoneNotFound from './ErrorZoneNotFound';

const errorIds = {
  '8B5AAB0A': <ErrorInvalidUrl />,
  CB38304B: <ErrorZoneNotFound />,
  '2B31C61A': <ErrorNowPlayingNotFound />,
  F3174B6F: <ErrorVoteDisabled />
};

const ErrorNotification = error => {
  const { offline, isMounted } = useConnectionChange();

  function getErrorMessage(errorId) {
    if (offline) {
      return <ErrorOffline />;
    } else if (errorIds[errorId]) {
      return errorIds[errorId];
    } else {
      return <ErrorGeneric />;
    }
  }

  return <div>{isMounted && getErrorMessage(error.id)}</div>;
};

export default ErrorNotification;
