import React from "react";
import { useVoteAppState } from "../../../hooks/useVoteAppState";
import TrackAlbumArt from "./TrackAlbumArt";
import TrackArtist from "./TrackArtist";
import TrackTitle from "./TrackTitle";

const TrackInfo = () => {
  const state = useVoteAppState();
  const { track } = state;

  return (
    <React.Fragment>
      <TrackTitle title={track.song} />
      <TrackAlbumArt {...track.images} />
      <TrackArtist artist={track.artist} />
    </React.Fragment>
  );
};

export default TrackInfo;
