import React from 'react';
import { useVoteAppDispatch } from '../../../../hooks/useVoteAppDispatch';
import { CLEAR_ERROR } from '../../../../state/actionTypes';

import FrameHeader from '../../../../common/FrameHeader';
import FrameBody from '../../../../common/FrameBody';
import Button from '../../../../common/Button';

const ErrorVoteDisabled = () => {
  const dispatch = useVoteAppDispatch();
  const clearError = () => dispatch({ type: CLEAR_ERROR });

  return (
    <div>
      <FrameHeader>uh oh!</FrameHeader>
      <FrameBody>
        <div>Sorry, but vote is disabled.</div>
      </FrameBody>
      <Button
        styles={{ width: '27px', marginRight: '12px' }}
        onClick={clearError}
      >
        OK
      </Button>
    </div>
  );
};

export default ErrorVoteDisabled;
