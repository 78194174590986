import {
  FETCH_TRACK_REQUEST,
  FETCH_TRACK_FAILURE,
  FETCH_TRACK_SUCCESS,
  SUBMIT_VOTE_FAILURE,
  CLEAR_ERROR,
  SUBMIT_VOTE_REQUEST,
  SUBMIT_VOTE_SUCCESS,
  FETCH_CONFIG_SUCCESS,
} from "../actionTypes";
import { modeKiosk, modeMobile } from "../../constants/mode";

const voteReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TRACK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_TRACK_SUCCESS:
      return {
        ...state,
        track: payload,
        isLoading: false,
        error: null,
      };
    case FETCH_TRACK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case SUBMIT_VOTE_REQUEST:
      return {
        ...state,
        vote: {
          ...state.vote,
          isLoading: true,
        },
      };
    case SUBMIT_VOTE_SUCCESS:
      return {
        ...state,
        vote: {
          ...state.vote,
          isLoading: false,
        },
      };
    case SUBMIT_VOTE_FAILURE:
      return {
        ...state,
        error: payload,
        vote: {
          ...state.vote,
          isLoading: false,
        },
      };
    case CLEAR_ERROR:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case FETCH_CONFIG_SUCCESS:
      const { apiUrl, ajaxRefreshDelay_msec, errorRetry_sec, mode } = payload;
      const isKiosk = mode === modeKiosk;
      const isMobile = mode === modeMobile;

      return {
        ...state,
        isLoading: false,
        appConfig: {
          ...state.appConfig,
          apiUrl,
          ajaxRefreshDelay_msec,
          errorRetry_sec,
          mode: {
            kiosk: isKiosk,
            mobile: isMobile,
          },
        },
      };
    default:
      return state;
  }
};

export default voteReducer;
