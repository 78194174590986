import React from 'react';
import { withRouter } from 'react-router-dom';
import { useFetchTrackData } from '../../../../hooks/useFetchTrackData';

import FrameHeader from '../../../../common/FrameHeader';
import FrameBody from '../../../../common/FrameBody';
import Button from '../../../../common/Button';

const ErrorOffline = ({ location }) => {
  const { fetchData } = useFetchTrackData(location.search);

  return (
    <div>
      <FrameHeader>uh oh!</FrameHeader>
      <FrameBody>
        <div>There is no internet connection, please re-connect.</div>
      </FrameBody>
      <Button styles={{ width: '90px' }} onClick={fetchData}>
        Retry Now
      </Button>
    </div>
  );
};

export default withRouter(ErrorOffline);
