import axios from 'axios';
import { useEffect, useCallback } from 'react';
import { useVoteAppState } from './useVoteAppState';
import { useVoteAppDispatch } from './useVoteAppDispatch';
import { useDocumentVisibility } from './useDocumentVisibility';
import { useConnectionChange } from './useConnectionChange';
import { keyParam } from '../constants/config';
import {
  FETCH_TRACK_REQUEST,
  FETCH_TRACK_SUCCESS,
  FETCH_TRACK_FAILURE
} from '../state/actionTypes';

export const useFetchTrackData = propsParams => {
  const state = useVoteAppState();
  const dispatch = useVoteAppDispatch();
  const hidden = useDocumentVisibility();
  const { offline } = useConnectionChange();

  const { apiUrl, ajaxRefreshDelay_msec } = state.appConfig;

  const query = new URLSearchParams(propsParams).get(keyParam);
  const allowFetch = !hidden && !state.error && window.navigator.onLine;

  const getFetchUrl = useCallback(() => {
    return `${apiUrl}?key=${query}`;
  }, [apiUrl, query]);

  const fetchData = useCallback(async () => {
    if (!offline) {
      const API_URL = getFetchUrl();

      dispatch({ type: FETCH_TRACK_REQUEST });
      try {
        const { data } = await axios.get(API_URL);
        dispatch({
          type: FETCH_TRACK_SUCCESS,
          payload: data.data[0]
        });
      } catch (e) {
        console.log(e.response);
        dispatch({ type: FETCH_TRACK_FAILURE, payload: e.response.data });
      }
    } else {
      return;
    }
  }, [dispatch, getFetchUrl, offline]);

  useEffect(() => {
    const refreshData = (fn, delay) => {
      fn();
      return setInterval(fn, delay);
    };

    if (allowFetch) {
      const intervalId = refreshData(fetchData, ajaxRefreshDelay_msec);

      return () => clearInterval(intervalId);
    }
  }, [ajaxRefreshDelay_msec, allowFetch, fetchData]);

  return { state, fetchData };
};
