import { useEffect, useState } from 'react';

export const useVoteCountdownTimer = vote => {
  const [count, setCount] = useState(5);

  useEffect(() => {
    if (vote.up && count > 0) {
      let id = setInterval(() => {
        setCount(count - 1);
      }, 1000);

      return () => {
        clearInterval(id);
      };
    } else if (vote.down && count > 0) {
      let id = setInterval(() => {
        setCount(count - 1);
      }, 1000);

      return () => {
        clearInterval(id);
      };
    }
  }, [vote, count]);

  return { count, setCount };
};
