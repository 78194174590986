import React, { useEffect, useRef, memo } from "react";
import NoImage from "../../../../assets/images/no-image.png";

const TrackAlbumArt = ({ "500": newImage }) => {
  const currentImageRef = useRef();

  // Sets default NoImage on error
  const setDefaultImage = e => (e.target.src = NoImage);

  const createCopyImageEl = src => {
    const img = new Image();
    img.src = src || NoImage;
    img.classList.add("rxmg-album");
    document.querySelector(".rxmg-album-component").appendChild(img);
    return img;
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      currentImageRef.current.classList.remove("rxmg-album-hidden");
    }, 150);

    return () => {
      const tempImage = createCopyImageEl(newImage);
      setTimeout(() => {
        tempImage.classList.add("rxmg-album-hidden");
      }, 150);
      setTimeout(() => {
        tempImage.parentElement.removeChild(tempImage);
      }, 2000);
      clearTimeout(timeoutId);
    };
  }, [newImage]);

  return (
    <div className="rxmg-album-component">
      <img
        ref={currentImageRef}
        src={newImage || NoImage}
        onError={setDefaultImage}
        alt="album-art"
        className="rxmg-album rxmg-album-hidden"
      />
    </div>
  );
};

export default memo(TrackAlbumArt);
