import { useEffect, useState } from 'react';

export const useAnimateTypewriter = text => {
  const [originalText, setOriginalText] = useState('');
  const [animatedText, setAnimatedText] = useState('');

  const animateTextForward = text => {
    for (let i = 0; i < text.length; i++) {
      setTimeout(
        () => setAnimatedText(animatedText => animatedText.concat(text[i])),
        65 * i
      );
    }
  };

  const animateTextBackward = text => {
    for (let i = text.length; i >= 0; i--) {
      setTimeout(() => {
        setAnimatedText(animatedText => animatedText.substring(0, i));

        // if animated text has been removed from screen, reset original text state
        if (i === 0) {
          setOriginalText('');
        }
      }, 50 * (text.length - i));
    }
  };

  useEffect(() => {
    if (originalText === '') {
      // set new original text state
      setOriginalText(text);
    } else if (originalText !== '' && originalText === text) {
      // animate original text from state forward
      animateTextForward(originalText);
    } else if (originalText !== '' && originalText !== text) {
      // detect new text passed through props and animate original text from state backward
      animateTextBackward(originalText);
    }
  }, [originalText, text]);

  return animatedText;
};
